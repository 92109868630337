<template>
  <AppPage :show-footer="false">
    <div v-if="storyboard" class="content-top">
      <header>
        <div class="sign-up">
          <img src="/img/storyfolder-logo.svg" alt="StoryFolder"/>
          <div>
            Turn videos into storyboards and notes. Create a free account and
            <router-link :to="{name: 'signup'}">Try StoryFolder</router-link>
          </div>
          <div v-if="false">
            To save your storyboards in one place, create a free account.
            <router-link :to="{name: 'signup'}">Try StoryFolder</router-link>
          </div>
        </div>
        <div class="storyboard-header">
          <div v-if="videoId">
            <youtube :video-id="videoId" />
          </div>
          <div>
            <h1>{{storyboard.title}}</h1>
            <p class="username">
              <span v-if="storyboard.userName">By <strong>{{ storyboard.userName }}</strong> on </span>
              {{createdDate}}
            </p>
            <div v-if="description" class="description" v-html="description" />
          </div>
          <div class="actions" v-if="false">
            <Button class="primary" label="Save to StoryFolder" size="small"/>
            <Button class="secondary" icon="arrow" label="Download" size="small" />
            <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{backgroundColor: network.color}"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
            >
              <i :class="network.icon"></i>
              <span>{{ network.name }}</span>
            </ShareNetwork>
          </div>
        </div>
      </header>
      {{storyboard.duration}}
      {{storyboard.visualGroundTruth}}
      <div class="view-selector-container">
        <div class="view-selector">
          <div
              v-for="view in views"
              :key="view.type"
              :class="{selected: selectedView === view.type}"
              @click="selectedView = view.type"
              v-tooltip="`View as ${view.label}`"
          >
            <IconSvg :name="`view-${view.type}`" />
          </div>
        </div>
      </div>
      <div class="shots" :class="selectedView">
        <div
            v-for="(shot, index) in storyboard.shots"
            :key="index"
            class="shot"
        >
          <span class="shot-number">{{index+1}}</span>
          <img :src="`/api/storyboards/${storyboard._id}/Shot-${index+1}.png`" loading="lazy" :alt="`Shot ${index+1}`" />
          <div class="metadata-container" v-if="selectedView !== 'grid'">
            <div v-for="label in Object.keys(shot.metadata || {})" class="metadata" :key="label">
              <label>{{label}}</label>
              <span>{{ formatMetadata(shot.metadata[label]) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content-centered">
      <ProgressSpinner v-if="loading" />
      <div v-else-if="showPasswordForm" class="form-password">
        <h4>Password:</h4>
        <InputPassword
          :value="password"
          @change="password=$event"
        />
        <Button
          class="small secondary full-width"
          :has-chevron="true"
          label="Continue"
          @click="load"
        />
        <p>
          {{error}}
        </p>
      </div>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import Button from "@/components/atoms/Button.vue";
import VueSocialSharing from 'vue-social-sharing'
import InputPassword from "@/components/atoms/InputPassword.vue";
import ProgressSpinner from "@/components/atoms/ProgressSpinner.vue";
import moment from 'moment';
import IconSvg from "@/components/atoms/IconSvg.vue";
import VueYouTubeEmbed from 'vue-youtube-embed'
import { getIdFromURL } from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed, { global: true })

Vue.use(VueSocialSharing);

export default Vue.extend({
  components: {
    IconSvg,
    ProgressSpinner,
    InputPassword,
    Button,
    AppPage,
  },
  data(){
    return {
      loading: false,
      password: null,
      showPasswordForm: false,
      error: null,
      views: [{type: 'storyboard', label: 'Storyboard'}, {type: 'grid', label: 'Thumbnails'}, {type: 'list', label: 'Shot List'}],
      selectedView: 'storyboard',
      networks: [
        // { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        // { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
        { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        // { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        // { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        // { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        // { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        // { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        // { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        // { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        // { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        // { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        // { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        // { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        // { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        // { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
        // { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        // { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        // { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        // { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        // { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' }
      ]
    }
  },
  computed: {
    storyboard(){
      return this.$store.getters['storyboards/byId'](this.$route.params.storyboardId)
    },
    sharing(){
      return {
        url: `https://storyfolder.com/b/${this.storyboard._id}`,
        title: `Storyboard for ${this.storyboard.title}`,
        description: 'Check out this storyboard I made with Storyfolder!',
        quote: 'Check out this storyboard I made with StoryFolder',
        hashtags: 'storyboard, storyfolder,',
        twitterUser: 'storyfolder'
      }
    },
    createdDate(){
      return moment(this.storyboard.createdAt).format('MMMM Do YYYY')
    },
    description(){
      let description = String(this.storyboard.description);
      description = description.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
      return description.split('\n').map(line => `<p>${line}</p>`).join('');// convert URLs to anchor tag links
    },
    videoId(){
      if(this.storyboard.sourceInfo && this.storyboard.sourceInfo.includes('youtu')){
        return getIdFromURL(this.storyboard.sourceInfo)
      }
      return null;
    }
  },
  mounted(){
    this.load();
  },
  methods: {
    load(){
      if(this.$route.params.storyboardId){
        this.loading = true;
        let request;
        if(this.password){
          request = this.$store.dispatch('storyboards/readSecurely', { id: this.$route.params.storyboardId, password: this.password })
        } else {
          request = this.$store.dispatch('storyboards/read', this.$route.params.storyboardId)
        }
        request.catch(e => {
            if(e.response.status === 401){
              // require password
              this.showPasswordForm = true;
              this.error = e.response.data
            }
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    formatMetadata(value){
      if(typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }else if(Array.isArray(value)){
        return value.join(', ')
      } else {
        return value
      }
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';
/deep/ .page-content{
  background: @white;
  color: @grayDark;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-width(){
  max-width: 1500px;
  width: 90vw;
  margin-right: auto;
  margin-left: auto;
}
.form-password input{
  background: @grayLight;
}
header{
  .page-width();
}
.sign-up{
  background: @grayLightHover;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  gap: 0.75em;
  padding: 0.75em;
  font-size: 90%;
  img{
    height: 1.5em;
  }
  a{
    font-weight: 600;
    border-bottom: 1px dotted @grayMedium;
    padding-bottom: 2px;
    color: @grayDark;
    &:hover{
      color: @black;
      border-color: @grayDark;
    }
  }
}
.storyboard-header{
  display:grid;
  margin-top: 2em;
  gap: 1em;
  width: 100%;
  button{
    margin: 0;
    font-weight: 600;
  }
  .actions{
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    gap: 1em;
  }
}
/deep/ iframe{
  max-width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
h1{
  margin-bottom: 0.2em;
  margin-top: 0em;
  font-size: 2.25rem;
}
p.username{
  font-weight: 600;
  margin-bottom: 0;
}
a{
  color: @blackSoft;
}
.description{
  display: inline-block;
  /deep/ p{
    font-weight: 500;
    font-size: 90%;
    color: @grayDark;
    max-width: 40em;
  }
  /deep/ a{
    color: @grayMedium;
  }
}

.view-selector-container{
  display: flex;
  justify-content: flex-end;
}
.view-selector{
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 0.5em;
  background: @grayLight;
  overflow: hidden;
  font-size: 1.75rem;
  cursor: pointer;
  div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25em 0.5em;
    color: @grayMedium;
    &.selected{
      background: @grayLightHover;
      .icon{
        color: @grayDark !important;
      }
    }
  }
  /deep/ svg{
    width: 1em;
    height: 1em;
  }
}
.shots{
  border-top: 2px solid @grayLight;
  padding-top: 2em;
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em auto 10em auto;
  gap: 1em;
  max-width: 1500px;
  .page-width();
  &.list{
    grid-template-columns: 1fr;
  }
}
.shot{
  img{
    width: 100%;
  }
  &:hover .shot-number{
    opacity: 1;
  }
}
.list .shot{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.shot-number{
  position: absolute;
  background: black;
  color: @white;
  padding: 0.1em 0.5em;
  border-bottom-right-radius: 0.5em;
  opacity: 0;
}

.metadata-container{
  font-size: 0.85em;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
  div{
    border-bottom: 1px solid @grayLight;
    padding: 0.5em 1em;
    &:last-child{
      border: none;
    }
  }
  label{
    display: block;
    text-transform: uppercase;
    font-size: 0.9em;
    color: @grayLightMedium;
    margin-right: 1em;
    font-weight: 600;
  }
  span{

  }
}
@media(min-width: @secondbreakpoint){
  .shots{
    grid-template-columns: 1fr 1fr;
  }
}
@media(min-width: @thirdbreakpoint){
  .shots{
    grid-template-columns: 1fr 1fr 1fr
  }
  .storyboard-header{
    gap: 2em;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    justify-content: stretch;
  }
}
@media(min-width: @fourthbreakpoint){

}
</style>
